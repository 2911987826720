* {
  box-sizing: border-box;
  font-size: 1vw;
  font-family: 'Raleway';
}

html {
  height: 100%;
}
body {
  margin: 0;
  height: 100%;
}
h1, h2, h3, h4, h5, h6,
p, strong, small, figure, img {
  margin: 0;
}
ol, ul {
  padding: 0;
  margin: 0;
}

#root {
  height: 100%;
  margin: 0;
  display: flex;
  // flex-direction: column-reverse;
  flex-direction: column;
  justify-content: space-between;
}

strong {
  font-size: inherit;
}

a {
  word-break: break-word;
}
a[href^="tel"] {
  color: inherit; /* Inherit text color of parent element. */
  text-decoration: none; /* Remove underline. */
  font-size: inherit;
  /* Additional css `propery: value;` pairs here */
}
a:hover {
  color: inherit;
}

.hidden {
  display: none !important;
}
.width-content {
  width: 83.334em;
}

.title--xxl {
  font-size: 3.334rem;
  font-family: 'Zen Dots';
  font-weight: 400;
  line-height: 1.1999em;
}
.title--standard {
  font-size: 2.223rem;
  font-family: 'Zen Dots';
  font-weight: 400;
  line-height: 1.1997em;
}
.subtitle {
  font-size: 1.667rem;
  font-family: 'Raleway';
  font-weight: 600;
  line-height: 1.174em;
}
.paragraph--standard {
  font-size: 1.112rem;
  font-family: 'Raleway';
  font-weight: 400;
  font-style: normal;
  line-height: 1.173em;
}
.paragraph--highlight {
  font-size: 1.112rem;
  font-family: 'Raleway';
  font-weight: 600;
  font-style: normal;
  line-height: 1.173em;
}
.paragraph--italic {
  font-size: 1.112rem;
  font-family: 'Raleway';
  font-weight: 400;
  font-style: italic;
  line-height: 1.173em;
}

.text--dark-purple {
  color: var(--blue-second-700);
}
.text--light-blue {
  color: var(--blue-900);
}
.text--gray-blue {
  color: var(--gray-blue);
}
.text--green {
  color: var(--turquoise-900);
}
.background-color--dark-purple {
  background-color: var(--blue-second-700);
}
.background-color--light-purple {
  background-color: var(--blue-800);
}

.hidden {
  display: none !important;
}

@media screen and (max-width: 1024px) {
  * {
    font-size: 2.243vw;
  }
  body {
    height: max-content;
  }

  .width-content {
    width: 41.24em;
    // overflow: hidden;
  }

  .title--xxl,
  .title--standard {
    font-size: 3.344rem;
  }
  .subtitle {
    font-size: 2.23rem;
  }
  .paragraph--standard {
    font-size: 1.951rem;
  }
  .paragraph--highlight {
    font-size: 2.23rem;
  }
}