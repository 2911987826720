.footer-main-page {
  height: min-content;
  padding: 2.355em 0 2.491em 0;
  display: flex;
  justify-content: center;

  &__social-media {
    display: flex;
    align-items: flex-end;
  }
  &__second-email {
    margin-top: .83em;
  }
  &__know-us-options {
    display: flex;
    margin-left: 1.042em;
    & p {
      width: max-content;
    }
    &__second-option {
      margin-left: 3.716em;
    }
  }
  &__robot-open-brain-image {
    width: 11.903em;
    & > img {
      width: 100%;
    }
  }
}
.dark-footer.footer-main-page {
  background-color: black;
}
.footer-main-page .footer_content {
  // max-width: 1920px;
  display: flex;
  justify-content: space-between;
}
.footer-main-page p {
  color: dark;
}
.dark-footer.footer-main-page p {
  color: white;
}
.dark-footer.footer-main-page .footer_main-text {
  color: white;
}
.footer-main-page .footer_contact {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: max-content;
  margin-top: 2.77em;
}
.footer-main-page .footer_contact_content {
  display: flex;
  margin-top: 1.66em;
}
.footer-main-page .footer_phone,
.footer-main-page .footer_email {
  align-items: center;
  color: inherit;
  display: flex;
  text-decoration: none;
}
.footer-main-page .footer_phone_image,
.footer-main-page .footer_email_image {
  width: 1.25em;
  height: auto;
}
.footer-main-page .footer_phone_image {
  margin-right: 0.417em;
}
.footer-main-page .footer_email_image {
  margin-right: 0.6777em;
}
.footer-main-page .footer_phone_number,
.footer-main-page .footer_email_email-address {
  width: max-content;
  margin: 0;
}
.footer-main-page .footer_second-part {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 3.254em;
}
.footer-main-page .footer_second-part_information_text {
  width: max-content;
  text-decoration: none;
}
.dark-footer.footer-main-page .footer_second-part_information_text {
  color: white;
}
.footer-main-page .footer_second-part_information {
  display: flex;
  padding: 0;
  margin: 0;
}
.footer-main-page .footer_second-part_information li {
  margin-right: 2.188em;
  list-style: none;
}

.footer-main-page .footer_social-media-container {
  width: 16.25em;
  height: min-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 12.639em;
}
.footer-main-page .footer_social-media {
  width: 2.084em;
  height: 2.084em;
  background-color: var(--turquoise-900);
  display: flex;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  & > img {
    width: 1.042em;
  }
}
.footer-links {
  text-decoration: none;
}
.footer-links:hover {
  color: var(--gray-blue);
}

@media screen and (max-width: 1024px) {
  .footer-main-page {
    padding: 4.584em 0 5.625em 0;
    display: flex;
    justify-content: center;
    & .footer_social-media {
      width: 4.18em;
      height: 4.18em;
      margin-right: .558em;
      & > img {
        width: 2.09em;
      }
    }
    & .footer_social-media-container {
      width: min-content;
      margin-right: 0;
      margin-bottom: 3.344em;
    }
    &__social-media {
      flex-direction: column;
      align-items: flex-start;
    }
    &__know-us-options {
      margin-left: 0;
      &__second-option {
        margin-left: 0;
      }
    }
    & .footer_contact_content {
      flex-direction: column;
    }
    &__know-us-options {
      flex-direction: column;
    }
    & .footer_second-part_information {
      flex-direction: column;
    }
    &__robot-open-brain-image {
      display: none;
    }
  }
  .footer_main-text {
    font-size: 4.46rem;
  }
}