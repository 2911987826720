@import '../../assets/styles/variables.scss';

/* Header */
.home-header {
  background-color: white;
  width: 100%;
  height: var(--header-height);
  display: flex;
  justify-content: center;
  align-items: center;
  // padding: 0 4.009em;
  position: fixed;
  z-index: 2;

  & .header_content {
    /* max-width: 1920px; */
    height: 100%;
    display: flex;
    /* grid-template-columns: max-content max-content max-content; */
    /* grid-template-areas: 'home-header__image-container home-header__navbar home-header__buttons-container'; */
    /* column-gap: 4.5%; */
  
    /* grid-template-rows: 100% 100% 100%; */
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
  & .home-header__navbar {
    grid-area: home-header__navbar;
    display: flex;
    /* align-items: center; */
    height: 100%;
    width: 32.848em;
    justify-content: space-between;
    margin-right: 1.945em;
  }
  & .header__content__image-container {
    height: min-content;
    grid-area: home-header__image-container;
  }
  & .header__content__image {
    width: 16.32em;
  }
  & .home-header__text {
    text-decoration: none;
    font-size: .834rem;
    margin: 0;
    font-weight: 700;
    height: min-content;
    text-transform: uppercase;
  
    &.self-center {
      align-self: center;  
    }
  }
  &__social-media-list {
    display: flex;
    list-style: none;
    margin: 0;

    &__list-content {
      margin-right: .278em;
    }
    &__container {
      background-color: var(--blue-800);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2.084em;
      height: 2.084em;
      border-radius: 50%;
      overflow: hidden;
    }
    &__icon {
      width: 1.042em;
    }
  }
  &__burger-button-container {
    display: flex;
    align-items: center;
  }
  &__burger-button {
    display: none;
    &__line {
      width: 2.369em;
      height: 2px;
      background-color: var(--blue-900);
      margin-bottom: 4px;
      border-radius: 12px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
#home-header__burger-button__input {
  display: none;
}
.home-header.dark-header {
  background-color: var(--dark);
}
.home-header.dark-header .home-header__text {
  color: white;
}

.home-header .home-header__navbar__options-title-container {
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  margin-top: 4em;
  padding: 0.521em 1.4066em 0.8856em 1.5625em;
  overflow: hidden;
  transition: .5s;
}
.home-header .home-header__navbar__plans-to-buy {
  height: min-content;
  display: flex;
  align-self: center;
  // margin-left: 1.625em;
  // margin-right: 1.925em;
}
.home-header__navbar__merchandising {
  height: min-content;
  display: flex;
  align-self: center;
}

.home-header .home-header__navbar__options-title-dropdown {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.home-header .home-header__navbar__options-burguer-dropdown {
  display: none;
}
.home-header .home-header__dropdown-options {
  list-style: none;
  margin-bottom: 0;
  padding: 0;
  margin-top: 0.734em;
}
.home-header .home-header__dropdown-option-container {
  margin-bottom: 0.42em;
  display: flex;
}
.home-header .home-header__dropdown-option-container:nth-child(9) {
  margin-bottom: 0;
}
.home-header__dropdown-option {
  color: var(--sky-blue-two);
  text-decoration: none;
  font-size: 0.8334rem;
}
.home-header__dropdown-option-container--category {
  display: none;
}

.home-header #checkbox-header-dropdown {
  display: none;
}
.home-header #checkbox-header-dropdown ~ .home-header__navbar__options-title-container {
  height: 3em;
}
.home-header #checkbox-header-dropdown:checked ~ .home-header__navbar__options-title-container {
  height: 15.7299em;
  background-color: var(--purple-two);
}
.home-header #checkbox-header-dropdown:checked ~ .home-header__navbar__options-title-container .home-header__navbar__options-title {
  color: white;
}
.home-header #checkbox-header-dropdown:checked ~ .home-header__navbar__options-title-container .home-header__arrow path {
  fill: white;
}

.home-header .home-header__arrow {
  width: 1.25em;
  height: auto;
  margin: 0;
  margin-left: 0.4688em;
  margin-bottom: 0.2em;
}
.home-header .home-header__arrow path {
  fill: var(--purple);
}
.home-header.dark-header .home-header__arrow path {
  fill: white;
}

.home-header .header_content_buttons_container {
  grid-area: home-header__buttons-container;
  display: flex;
  align-items: center;
}
.home-header .header_content_button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25rem;
  text-decoration: none;
  width: 9.667em;
  height: 2.417em;
  border-radius: 0.625em;
  font-weight: 700;
}

.signin_button {
  background-color: transparent;
  color: var(--purple);
}
.dark-header .signin_button {
  color: var(--green);
}
.dark-header .button-signup-signin {
  background-color: var(--green);
}
.home-header .header_content_button.signin_button {
  background-color: white;
  margin-right: 1.125em;
}
/* end Header */

@media screen and (max-width: 1024px) {
  #home-header__burger-button__input:checked ~ .home-header__burger-button-container {
    width: 26.472em;
    height: 79.562em;
    background-color: var(--blue-800);
  }
  #home-header__burger-button__input:checked ~ .home-header__burger-button-container .home-header__burger-button__line {
    background-color: white;
  }
  .home-header{
    & .header_content {
      width: 100%;
      padding-left: 1.672em;
    }
    & .home-header {
      &__navbar {
        flex-direction: column;
        width: max-content;
        height: 38.88em;
        align-items: flex-end;
        justify-content: flex-end;
        text-align: right;
        margin-top: .558em;
        margin-right: 1.951em;
      }
      &__text {
        font-size: 2.23rem;
        color: white;
        margin-bottom: 1.687em;
        &:last-child {
          margin-bottom: 0;
        }
      }
      &__social-media-list {
        flex-wrap: wrap-reverse;
        width: 14.3em;
        justify-content: flex-end;
        margin-top: 13.097em;
        margin-right: auto;
        margin-left: auto;
        &__list-content {
          margin-right: .558em;
          margin-bottom: 1.672em;
        }
        &__container {
          width: 4.18em;
          height: 4.18em;
          background-color: var(--turquoise-900);
        }
        &__icon {
          width: 2.09em;
        }
      }
    }
    & .header__content__image {
      width: 5.991em;
    }
    &__burger-button-container {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      background-color: white;
      width: 5.8em;
      height: 8.5em;
      border-radius: 5px;
      overflow: hidden;
      transition: .5s;
    }
    &__burger-button {
      display: flex;
      flex-direction: column;
      margin-top: 3.762em;
      margin-right: 2.021em;
      margin-bottom: 3.762em;
    }
    & .header_content_buttons_container {
      margin-right: 6.131em;
    }
  }
}