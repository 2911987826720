.sign-up__main {
  // background-image: url("../../src/assets/images/epic-team-picture-2x--03.png");
  background-repeat: no-repeat;
  background-size: 200%;
  background-position-x: center;
  background-position-y: center;
  height: max-content;
  padding-top: var(--header-height);
  display: flex;
  justify-content: center;
  align-items: center;
}
.sign-up__form {
  background-color: white;
  border-radius: 15px;
  margin-top: 5.599em;
  margin-bottom: 5.2345em;
  padding-top: 1.615em;
  padding-bottom: 1.4587em;
  width: 24.271em;
  /* height: 38.594em; */
}

.sign-up__form__information-container {
  margin-left: 1.5625em;
}
.sign-up__form__title-container {
  display: flex;
  align-items: center;
}
.sign-up__form__title__icon {
  margin: 0;
  display: flex;
  height: min-content;
  width: 1.3544em;
  margin-right: 0.7813em;
}
.sign-up__form__title__icon img {
  width: 100%;
}
.sign-up__title {
  font-family: 'Zen Dots';
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.3617em;
  margin: 0;
  font-weight: 400;
}
.sign-up__form__description {
  font-size: 0.8334rem;
  line-height: 1.362em;
  width: 25.8105em;
  margin-top: 2.1877em;
  margin-bottom: 0.313em;
}
.sign-up__form__small {
  font-size: 0.625rem;
  color: var(--purple);
  margin-bottom: 0;
}


.sign-up__form__personal-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.094em;
}
.sign-up__form__inputs-in-line {
  display: flex;
}
.sign-up__form__inputs-in-line .form-input-container:first-child {
  width: 9.948em;
  margin-right: 0.365em;
}
.sign-up__form__inputs-in-line .form-input-container:last-child {
  width: 12.24em;
}
.sign-up__form__personal-data > .form-input-container {
  margin-bottom: 0.417em;
}

.sign-up__form__epic-universe-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.875em;
  margin-bottom: 1.4066em;
}
.sign-up__form__epic-universe-data__title {
  margin: 0;
  font-size: 0.7296rem;
  font-weight: 400;
  margin-bottom: 1em;
  margin-left: 2.142em;
  align-self: flex-start;
}
.sign-up__form__epic-universe-data .sign-up__form__inputs-in-line {
  margin-bottom: 0.8856em;
}
.sign-up__form__epic-universe-data__group-input-container {
  width: 22.5522em;
}
.sign-up__form__epic-universe-data__group-input-container .form-input-container {
  padding: 0;
  width: 12.7087em;
  height: 2.7087em;
  background-color: transparent;
  position: relative;
}

#sign-up__form__epic-universe-data__dropdown-input {
  display: none;
}
.sign-up__form__epic-universe-data__group-input-container__input-container {
  padding-left: 0;
  background-color: var(--purple-two);
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 13.2299em;
  transition: .3s;
  border-radius: 15px;
  padding: .417em 1em .417em .7296em;
}
.sign-up__form__epic-universe-data__dropdown-screen {
  /* background-color: rgba(0, 0, 0, .5); */
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  cursor: pointer;
  z-index: -1;
}
.sign-up__form__epic-universe-data__group-input-container__input-container .form-input__label {
  transition: .2s;
  margin-bottom: .3em;
}
#sign-up__form__epic-universe-data__dropdown-input:checked ~ .sign-up__form__epic-universe-data__group-input-container__input-container {
  height: 2.7087em;
  background-color: var(--sky-blue);
}
#sign-up__form__epic-universe-data__dropdown-input:checked ~ .sign-up__form__epic-universe-data__dropdown-screen {
  z-index: 1;
}
#sign-up__form__epic-universe-data__dropdown-input:not(:checked) ~ .sign-up__form__epic-universe-data__group-input-container__input-container .form-input__label {
  color: white;
}
#sign-up__form__epic-universe-data__dropdown-input:not(:checked) ~ .sign-up__form__epic-universe-data__group-input-container__input-container #sign-up-group {
  visibility: hidden;
}
#sign-up__form__epic-universe-data__dropdown-input:not(:checked) ~ .sign-up__form__epic-universe-data__group-input-container__input-container .sign-up__form__epic-universe-data__group-input-container__arrow path {
  fill: white;
}

.sign-up__form__epic-universe-data__group-input-container__middle-part {
  display: flex;
  justify-content: space-between;
}
.sign-up__form__epic-universe-data__group-input-container__middle-part__content {
  display: flex;
  flex-direction: column;
}
.sign-up__form__epic-universe-data__group-input-container__arrow-container {
  cursor: pointer;
  margin-top: .5em;
  display: flex;
  height: min-content;
}
.sign-up__form__epic-universe-data__group-input-container__arrow {
  margin: 0;
  width: 1.25em;
  display: flex;
}
.sign-up__form__epic-universe-data__group-input-container__arrow path {
  fill: var(--purple);
  transition: .2s;
}

.sign-up__form__epic-universe-data__list {
  margin: 0;
  margin-top: 100%;
  list-style: none;
  padding: 0;
  transition: .2s;
}
#sign-up__form__epic-universe-data__dropdown-input:not(:checked) ~ .sign-up__form__epic-universe-data__group-input-container__input-container .sign-up__form__epic-universe-data__list {
  margin-top: 0.0523em;
}
.sign-up__form__epic-universe-data__list__option-container {
  margin-bottom: 0.26em;
  cursor: pointer;
}
.sign-up__form__epic-universe-data__list__option-container:last-child {
  margin: 0;
}
.sign-up__form__epic-universe-data__list__option {
  color: var(--sky-blue-two);
  margin: 0;
  font-size: 0.8334rem;
}


.sign-up__form .form-input__further-options__password-container {
  display: flex;
  margin-top: 0.9377em;
}
.sign-up__form .form-input__further-options-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sign-up__form .form-input__recover-password {
  font-size: 0.625rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--purple);
  margin-left: 0.25em;
}
.sign-up__form .form-input__further-options__password-container__text {
  margin: 0;
  display: flex;
  font-size: 0.625rem;
}
.sign-up__form .form-input__further-options__recover-password__arrow {
  display: flex;
  height: min-content;
  margin: 0;
  margin-left: 0.521em;
  width: 0.5555em;
}
.sign-up__form .form-input__further-options__recover-password__arrow img {
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .sign-up__main {
    background-size: 470%;
    background-position-x: 53%;
  }
  .sign-up__form {
    width: 33.959em;
  }
  .sign-up__form__title__icon {
    width: 1.876em;
  }
  .sign-up__title {
    font-size: 2.084rem;
  }
  .sign-up__form__description {
    font-size: 1.25rem;
    margin-top: .7em;
  }
  .sign-up__form__small {
    font-size: 1.25rem;
  }
  .sign-up__form__inputs-in-line .form-input-container:first-child {
    width: 13.959em;
    margin-right: .522em;
  }
  .sign-up__form__inputs-in-line .form-input-container:last-child {
    width: 17.084em;
  }
  .sign-up__form__personal-data > .form-input-container {
    margin-bottom: .625em;
  }
  .sign-up__form__epic-universe-data__group-input-container .form-input-container {
    /* width: 17.813em; */
    width: 21.564em;
    height: 4.22em;
  }
  .sign-up__form__epic-universe-data__group-input-container__input-container {
    height: 19.375em;
  }
  #sign-up__form__epic-universe-data__dropdown-input:checked ~ .sign-up__form__epic-universe-data__group-input-container__input-container {
    height: 4.22em;
  }
  .sign-up__form__epic-universe-data__list__option {
    font-size: 1.667rem;
  }
  .sign-up__form__epic-universe-data__group-input-container {
    width: 31.563em;
  }
  .sign-up__form__epic-universe-data__group-input-container__input-container .form-input__label {
    margin-bottom: 0;
  }
  .sign-up__form .form-input__further-options__password-container__text {
    font-size: 1.25rem;
  }
  .sign-up__form .form-input__recover-password {
    font-size: 1.25rem;
  }
}