@import '../../assets/styles/fonts.scss';

.home-main {
  padding-top: var(--header-height);
}

/* Hero */
.home-hero {
  color: white;
  display: flex;
  justify-content: center;
  padding-top: 3.681em;
  padding-bottom: 7.292em;
}
.home-hero__content {
  display: flex;
  justify-content: space-between;
  height: 34.584em;
  background-size: cover;
}
.home-hero__text-container {
  width: 36em;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 4.862em;
}
.home-hero__title {
  // width: 16.2em;
  margin: 0;
}
.home-hero__subtitle {
  font-family: 'Zen Dots';
  font-weight: 400;
  font-size: 1.875rem;
  margin-top: 0;
  margin-bottom: 0;
}
.home-hero__text {
  line-height: 1.3617em;
  margin-top: .4em;
  margin-bottom: .71em;
}
.home-hero__know-more {
  color: var(--purple);
  text-decoration: none;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  font-weight: 700;
}
.home-hero__know-more__arrow {
  font-size: 1.25rem;
  margin-left: 0.9375em;
}
.home-hero__figure-container {
  margin-right: 3.195em;
}
.home-hero__image-container {
  border-radius: 0.7813em;
  overflow: hidden;
  margin: 0;
  display: flex;
  width: 37.848em;
}
.home-hero__image-container img {
  width: 100%;
}

/* Enrollment */
.home-enrollment {
  display: flex;
  justify-content: center;
  padding: 2.431em 0;

  &__container {
    display: flex;
    justify-content: space-between;
  }
  &__image-information {
    position: relative;
    width: min-content;
    height: min-content;

    &__date {
      position: absolute;
      top: 0;
      text-align: center;
      left: 4.1244em;
    }
    &__block {
      background-repeat: no-repeat;
      background-size: cover;
      width: 23.743em;
      height: 9.137em;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      position: absolute;
      bottom: .447em;
      left: -1.042em;
      
      &__text {
        transform: rotate(-2.68deg);
        margin-top: .1em;
      }
    }
  }
  &__image-container {
    width: 34.653em;

    & img {
      width: 100%;
    }
  }
  &__further-information {
    margin-top: 1.25em;
    width: 40.834em;
    
    &__description {
      margin-top: .6em;
      margin-bottom: 1.85em;
      font-size: 1.389rem;
    }
    &__options-container {
      margin-top: 2.292em;
    }
  }
}
/* end Enrollment */


/* Video section */
.home-video-section {
  display: flex;
  justify-content: center;
  padding: 4.445em 0;

  &__further-information-container {
    display: flex;
    justify-content: space-between;
    margin-top: 2.57em;

    &__video-box {
      background-repeat: no-repeat;
      background-size: cover;
      width: 39.584em;
      height: 31.112em;
      display: flex;
      position: relative;
      margin-top: 1.876em;

      &__video-container {
        width: 38.542em;
        height: 21.32em;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        position: absolute;
        top: 5.556em;
        left: 8.889em;

        & video {
          border-radius: 5px;
          // background-color: var(--blue-second-800);
          width: 37.014em;
          height: 20.487em;
        }
      }
    }
    &__worlds-container {
      background-repeat: no-repeat;
      background-size: cover;
      width: 32.153em;
      height: 32.778em;
      position: relative;
      
      &__text-first {
        position: absolute;
        top: 1.999em;
        right: 0;
        width: 12.5em;
      }
      &__text-second {
        position: absolute;
        top: 13.053em;
        left: 0;
        width: 13em;
        text-align: end;
      }
      &__text-third {
        position: absolute;
        bottom: 3.56em;
        right: 0;
        width: 10em;
      }
    }
  }
}
/* end Video section */


/* worlds section */
.home-worlds {
  display: flex;
  justify-content: center;
  padding-top: 1.742em;
  padding-bottom: 1.945em;

  &__text-container {
    display: flex;
    justify-content: space-between;

    &__content {
      width: 45.764em;

      &__title {
        margin-top: .8124em;
        margin-bottom: .5em;
      }
    }
  }
  &__open-your-center {
    background-repeat: no-repeat;
    background-size: cover;
    width: 30.755em;
    height: 17.143em;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__curved-line {
      width: 17.862em;
      position: absolute;
      top: 0.006em;
      left: -8.026em;
    }
    &__you-want-to-open-a-center {
      font-family: "Zen Dots";
      font-weight: 400;
      width: 15em;
      text-align: center;
      margin-top: .4em;
      margin-bottom: .667em;
    }
  }
}
/* end Worlds section */


/* Sign up now */
.home-sign-up-now {
  display: flex;
  justify-content: center;
  padding-top: 5em;
  padding-bottom: 4.237em;
  &__title {
    align-self: flex-start;
    margin-bottom: .25em;
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    &__description {
      display: flex;
      justify-content: space-between;
    }
  }
  &__one-world-image {
    margin-top: 2.084em;
    margin-bottom: .834em;
  }
  &__entrepreneurship-text {
    margin-bottom: .2088em;
  }
  &__entrepreneurship-technology-text {
    margin-top: -1.27em;
    margin-bottom: .2088em;
  }
  &__schedule-block {
    width: 42.639em;
    height: 23.542em;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 2.848em;
    & > img {
      width: 40.417em;
      height: 22.292em;
      background-color: black;
      border-radius: 5px;
      color: white;
    }
  }
  &__our-community-block {
    width: 23.743em;
    height: 9.137em;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    top: -6.598em;
    right: 1.813em;
    &__text {
      transform: rotate(2.29deg);
      margin-top: .2em;
    }
  }
  &__our-community-information {
    text-align: center;
    margin-top: 1.112em;
    margin-bottom: 1.945em;
  }
}
/* end Sign up now */

/* Sponsors */
.home-sponsors {
  padding-top: 1em;
  padding-bottom: 5em;
  &__content {
    margin: auto;
    &__title-container {
      background-repeat: no-repeat;
      background-size: 38.056em;
      background-position-x: 36.5em;
      background-position-y: -5em;
      height: 14em;
      display: flex;
      align-items: center;
    }
    &__sponsors-list {
      display: flex;
      align-items: center;
      &__image-container {
        width: 10em;
        height: min-content;
        margin-right: 4em;
        & img {
          width: 100%;
        }
      }
    }
  }
}
/* end Sponsors */

/* Press */
.home-press {
  padding-top: 3.889em;
  padding-bottom: 4.306em;
  background-color: var(--gray-100);
  &__content {
    margin: auto;
  }
  &__title {
    width: 53%;
  }
  &__press-reviews-list {
    display: flex;
    justify-content: space-between;
    height: 20.834em;
    margin-top: 3.264em;
    &__article {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      text-align: center;
      width: 23.043em;
      &__text {
        font-size: 1.389rem;
        line-height: 1.1745em;
      }
    }
    &__first-image{
      width: 19.874em;
    }
    &__second-image {
      width: 24.812em;
    }
    &__third-image {
      width: 10.912em;
    }
  }
}
/* end Press */

/* Faqs */
.home-faqs {
  display: flex;
  justify-content: center;
  padding-top: 4.237em;
  padding-bottom: 5.556em;
  &__content {
    padding-top: 2.153em;
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 35em;
  }
  &__description {
    width: 20em;
    margin-top: .5em;
  }
  &__questions-list {
    margin-top: 3.959em;
    &__question-answer-container {
      width: 55.556em;
      height: min-content;
      margin-bottom: .764em;
    }
  }
  &__input-question {
    display: none;
  }
  &__question-container {
    background-color: var(--gray-100);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: .973em 1.667em;
    border-radius: 2.5px;
    &__text {
      width: 36em;
    }
    & > img {
      transition: .5s;
    }
  }
  &__answer-container {
    height: 0;
    overflow: hidden;
    padding: 0 1.667em;
    transition: .5s;
    border-radius: 2.5px;
    &__text {
      color: white;
      font-size: .973rem;
      line-height: 1.174em;
      margin-top: 1.713em;
    }
  }
  &__input-question:checked ~ &__answer-container {
    height: 10em;
  }
  &__input-question:checked ~ &__question-container > img {
    transform: rotate(90deg);
  }
  &__contact-text {
    width: 24em;
    margin-top: 3.458em;
    margin-bottom: 1em;
  }
}
#home-faqs-first-question:checked ~ .home-faqs__answer-container {
  height: 19.212em;
}
#home-faqs-second-question:checked ~ .home-faqs__answer-container {
  height: 17.992em;
}
#home-faqs-third-question:checked ~ .home-faqs__answer-container {
  height: 29.306em;
}
#home-faqs-fourth-question:checked ~ .home-faqs__answer-container {
  height: 24.75em;
}
#home-faqs-fifth-question:checked ~ .home-faqs__answer-container {
  height: 5.348em;
}
#home-faqs-sixth-question:checked ~ .home-faqs__answer-container {
  height: 9.906em;
}
/* end Faqs */


/* offices */
.home-offices {
  display: flex;
  justify-content: center;
  padding-top: 3.681em;
  padding-bottom: 3.889em;

  &__title {
    width: 22em;
  }
  &__further-information {
    display: flex;
    justify-content: space-between;

    &__description {
      margin-top: 1em;
      margin-bottom: 1.5em;
    }
  }
  &__image {
    display: flex;
    width: 55.973em;

    & img {
      width: 100%;
    }
  }
}
/* end Offices */


/* worlds */
.worlds {
  background-color: var(--sky-blue-four);
  display: flex;
  justify-content: center;
  padding: 6.4587em 0 1.4587em 0;
}
.worlds__content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.worlds__content__main-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 44em;
  margin-bottom: 5.1044em;
  text-align: center;
}
.worlds__main-text__title {
  font-family: 'Zen Dots';
  font-weight: 400;
  font-size: 1.875rem;
  margin: 0;
}
.worlds__main-text-description {
  font-size: 1.25rem;
  margin: 0;
  margin-top: 0.625em;
}
.worlds__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 82.917em;
}
.worlds__list__world {
  width: 25.8334em;
  height: 32.5523em;
  margin-bottom: 2.8646em;
  position: relative;
  border-radius: 15px;
  overflow: hidden;
}
.worlds__list__world__image-container {
  margin: 0;
  width: 100%;
}
.worlds__list__world__image {
  width: 100%;
}
.worlds__list__world__title-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 4.317em;
  padding-bottom: 0.7246em;
  padding-left: 1.1023em;
  display: flex;
  align-items: flex-end;
  /* border: 1px solid red; */
  background: linear-gradient(to top, rgba(49, 0, 224, .39) 35%, rgba(49, 0, 224, .04));
  overflow: hidden;
  border-radius: 15px 15px 0 0;
}
.worlds__list__world__title {
  margin: 0;
  font-size: 1.25rem;
  color: white;
  font-weight: 400;
}

/* .worlds__list__world__block-click {
  background-color: transparent;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: all-scroll;
} */
/* .worlds__list__world:hover .worlds__list__world__block-click {
  animation-name: hidden;
  animation-fill-mode: forwards;
  animation-duration: 0s;
  animation-delay: .1s;
  z-index: 10;
} */

/* @keyframes hidden {
  to {
    z-index: -1;
  }
} */

.worlds__list__world__description {
  /* z-index: 1; */
  position: absolute;
  top: 100%;
  background-color: var(--sky-blue-five);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: .1s;
  text-decoration: none;
}
.worlds__list__world:hover .worlds__list__world__description {
  /* animation-name: hidden-card;
  animation-duration: .1s;
  animation-fill-mode: forwards; */
  top: 0;
}
.worlds__list__world__description-text {
  color: black;
  font-size: 1.042rem;
  line-height: 1.3617em;
  margin: 0;
}
.worlds__list__world__description-text--main-text {
  color: black;
  font-size: 1.042rem;
  line-height: 1.3617em;
  margin: 0;

  font-family: 'Zen Dots';
  font-weight: 400;
}

.worlds__list__world__description-text-epickids {
  width: 20.694em;
}
.worlds__list__world__description-text-nextgeneration {
  width: 19.694em;
}
.worlds__list__world__description-text-genuine {
  width: 19.594em;
}
.worlds__list__world__description-text-oldschool {
  width: 18.894em;
}
.worlds__list__world__description-text-teacherinnovation {
  width: 19.694em;
}
.worlds__list__world__description-text-ecommerce {
  width: 17.695em;
}

.worlds-call-to-action {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.worlds-call-to-action__title {
  font-size: 1.875rem;
}

.worlds-call-to-action__login {
  background-color: transparent;
  color: var(--purple);
  font-weight: 500;
}
/* end worlds */


/* Be a mentor */
.be-a-mentor {
  background-color: var(--sky-blue-six);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4.167em;
  padding-bottom: 4.255em;
}
.be-a-mentor__main-title {
  margin-bottom: 0.6945em;
  font-family: 'Zen Dots';
  font-weight: 400;
}
.be-a-mentor__main-description {
  width: 53em;
}
.be-a-mentor__mail {
  display: flex;
  align-items: center;
}
.be-a-mentor__mail__icon-container {
  margin: 0;
  display: flex;
  width: 1.042em;
  margin-right: 0.7813em;
}
.be-a-mentor__mail__icon-container img {
  width: 100%;
}
.be-a-mentor__mail__mail-information {
  margin: 0;
  color: var(--purple);
}
.be-a-mentor .mentor-plan {
  margin-top: 2.31em;
}


/* Epic plans */
.plans-to-buy {
  padding-top: 3.646em;
  padding-bottom: 5.2087em;
}
.plans-to-buy__content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.plans-to-buy__main-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 65.1044em;
  margin-bottom: 2.865em;
}
.plans-to-buy__title {
  font-family: 'Zen Dots';
  font-weight: 400;
  font-size: 1.875rem;
  margin-top: 0;
  margin-bottom: 0.5em;
}
.plans-to-buy__description {
  font-size: 1.25rem;
  line-height: 1.3617em;
  text-align: center;
  margin: 0;
}
/* end Epic plans */

.open-your-epic-school {
  background-color: var(--sky-blue-nine);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5.985em;
  padding-bottom: 3.465em;
}
.open-your-epic-school__title {
  font-size: 1.89rem;
  margin: 0;
}
.open-your-epic-school__description {
  font-size: 1.05rem;
  margin: 0;
  margin-top: 1.8em;
  margin-bottom: 1.2em;
}


/* Lottery Modal */
.lottery-modal.hidden {
  display: none !important;
}
.lottery-modal {
  position: fixed;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lottery-modal__content {
  background-color: var(--purple-two);
  border-radius: 15px;
  width: 45.209em;
  padding-top: 2.6em;
  padding-bottom: 3.125em;
  padding-left: 3.021em;
  position: relative;
}
.modal__close-button {
  position: absolute;
  top: .3em;
  right: .7em;
  color: white;
  background: transparent;
  border: 0;
  outline: none;
  padding: 0;
  font-size: 1.8rem;
  cursor: pointer;
}
.modal__title {
  font-family: 'Zen Dots';
  font-weight: 400;
  color: white;
  font-size: 3.334rem;
  margin: 0;
}
.modal__description {
  color: white;
  font-size: 1.25rem;
  font-weight: 500;
  margin: 0;
  margin-top: 2.3em;
}
.modal__button {
  background-color: white;
  color: var(--purple);
  margin-top: 2.2088em;
}


@keyframes hidden-card {
  0% {
    top: 100%;
  }

  100% {
    top: 0%;
  }
}

@media screen and (max-width: 1024px) {
  .home-main {
    /* display: grid; */
    /* grid-template-areas: "home-hero"
                        "worlds"
                        "plans-to-buy"
                        "be-a-mentor"
                        "open-your-epic-school"; */
  }

  .home-hero {
    padding-bottom: 5.313em;
    /* grid-area: home-hero; */
  }
  .home-hero__content {
    flex-direction: column;
    margin-left: 0;
    width: 100%;
    height: min-content;
    align-items: center;
    background-image: none !important;
  }
  .home-hero__text-container {
    margin: 0;
  }
  .home-hero__title {
    width: 100%;
  }
  .home-hero__text {
    width: 100%;
  }
  .home-hero__figure-container {
    width: 100%;
    margin-top: 5.058em;
    display: none;
  }
  .home-hero__image-container {
    width: 29.688em;
    height: 48.928em;
    border-radius: 0;
    margin-top: 0;
    justify-content: center;
    align-items: center;
    object-fit: cover;
  }
  .home-hero__image-container img {
    height: 100%;
    object-fit: cover;
  }

  /* Enrollment */
  .home-enrollment__container {
    flex-direction: column;
  }
  .home-enrollment {
    &__image-information {
      margin: auto;

      &__block {
        width: 32.881em;
        height: 12.818em;
        bottom: -2.508em;
        left: -.558em;
      }
      &__date {
        left: 1.643em;
      }
    }
    &__image-container {
      width: 37.2em;
      display: flex;
      justify-content: flex-end;
      & > img {
        width: 112%;
      }
    }
    &__further-information {
      margin-top: 5.852em;
      &__description {
        font-size: initial;
      }
    }
  }
  /* end Enrollment */

  /* Video Section */
  .home-video-section {
    &__further-information-container {
      flex-direction: column;
    }
  }
  /* end Video Section */

  /* worlds */
  .home-video-section {
    &__further-information-container {
      &__video-box {
        width: 41.24em;
        height: 32.323em;
        &__video-container {
          width: 100%;
          height: 22.85em;
          right: auto;
          left: auto;
          & > video {
            width: 39.568em;
            height: 22.013em;
          }
        }
      }
      &__worlds-container {
        width: 100%;
        // height: 48.903em;
        height: 42em;
        &__text-first {
          width: 10.5em;
          top: 0;
        }
        &__text-second {
          width: 10em;
          top: 9em;
        }
        &__text-third {
          width: 8em;
          bottom: .6em;
        }
      }
    }
  }
  /* end worlds */

  /* home worlds */
  .home-worlds {
    &__content {
      width: 100%;
    }
    &__text-container {
      flex-direction: column;
      &__content {
        width: 36em;
        margin-left: 1.672em;
      }
    }
    &__open-your-center {
      margin-left: 1.381em;
      width: 40.664em;
      height: 22.633em;
      &__curved-line {
        width: 35.39em;
        left: -22.28em;
        z-index: 0;
      }
    }
  }
  /* end home worlds */

  /* further information */
  .home-offices {
    &__title {
      width: 12em;
    }
    &__further-information {
      flex-direction: column;
    }
    &__image {
      width: 40.265em;
    }
  }
  /* end further information */

  /* Home sign up now */
  .home-sign-up-now {
    &__content {
      &__button {
        display: none;
      }
    }
    &__schedule-video {
      display: none;
    }
    &__entrepreneurship-text,
    &__entrepreneurship-technology-text {
      font-size: 3.344rem;
    }
  }
  /* end Home sign up now */

  /* home press */
  .home-press {
    &__title {
      width: 100%;
    }
    &__press-reviews-list {
      width: 20em;
      overflow: hidden;
      margin-right: auto;
      margin-left: auto;
    }
  }
  /* end home press */

  /* Faqs */
  .home-faqs {
    &__content {
      background-image: none !important;
    }
    &__description {
      width: 100%;
    }
    &__questions-list {
      &__question-answer-container {
        width: 100%;
        margin-bottom: 1.673em;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    &__question-container {
      padding: 1.952em 3.344em;
    }
    &__answer-container {
      padding: 0 3.344em;
      &__text {
        font-size: 1.672rem;
      }
    }
    &__contact-text {
      width: 100%;
    }
  }
  #home-faqs-first-question:checked ~ .home-faqs__answer-container {
    height: 69.8em;
  }
  #home-faqs-second-question:checked ~ .home-faqs__answer-container {
    height: 70em;
  }
  #home-faqs-third-question:checked ~ .home-faqs__answer-container {
    height: 107em;
  }
  #home-faqs-fourth-question:checked ~ .home-faqs__answer-container {
    height: 82.2em;
  }
  #home-faqs-fifth-question:checked ~ .home-faqs__answer-container {
    height: 15.1em;
  }
  #home-faqs-sixth-question:checked ~ .home-faqs__answer-container {
    height: 30.9em;
  }
  /* end Faqs */

  .worlds__content__main-text {
    width: 32.709em;
    text-align: center;
  }
  .worlds__list {
    width: 100%;
    justify-content: center;
  }
  .worlds__list__world {
    width: 35.938em;
    height: 21.564em;
  }
  .worlds__list__world__title-container {
    height: 8.125em;
    padding-bottom: 2.084em;
    padding-left: 2.293em;
  }
  .worlds-call-to-action__title {
    display: none;
  }
  .worlds__list__world__description-text-epickids,
  .worlds__list__world__description-text-nextgeneration,
  .worlds__list__world__description-text-genuine,
  .worlds__list__world__description-text-oldschool,
  .worlds__list__world__description-text-teacherinnovation,
  .worlds__list__world__description-text-ecommerce {
    width: 23.584em;
  }

  .be-a-mentor {
    /* grid-area: be-a-mentor; */
    padding-top: 2.188em;
    padding-bottom: 3.125em;
  }
  .be-a-mentor__main-title {
    
    width: 16em;
    text-align: center;
  }
  .be-a-mentor__main-description {
    width: 29em;
  }

  .plans-to-buy {
    /* grid-area: plans-to-buy; */
    padding-top: 1.355em;
    padding-bottom: 4.064em;
  }
  .plans-to-buy__main-text-container {
    width: 36.25em;
  }

  .open-your-epic-school {
    /* grid-area: open-your-epic-school; */
    padding-top: 6.25em;
    padding-bottom: 4.376em;
    background-color: var(--sky-blue-six);
  }
  .open-your-epic-school__description {
    margin-bottom: 4.83em;
    width: 19.667em;
    text-align: center;
  }
  .be-a-mentor__mail__icon-container {
    width: 1.876em;
    margin-right: 1.667em;
  }

  /* Modal */
  .lottery-modal__content {
    width: 90%;
  }
}