.building-body {
  height: max-content;
}
.building {
  display: flex;
  justify-content: center;
  align-items: center;
  // margin-top: var(--header-height);
  width: 100%;
  // height: 45.625em;
  height: 100%;
  position: relative;
  overflow: hidden;
  padding-bottom: 3em;
}

.building__text-container {
  width: 60em;
  height: min-content;
}
.building__title {
  font-size: 5rem;
  margin: 0;
  font-family: 'Zen Dots';
}

.building__subtitle {
  font-size: 2.5rem;
  font-weight: 700;
  /* margin-top: 0; */
  width: 20em;
}
.building__subtitle2 {
  font-family: 'Zen Dots';
  font-size: 2.5rem;
  color: var(--purple);
  margin-bottom: 0;
  margin-top: 2em;
}
.building__button {
  display: inline-flex;
}

.building__image {
  /* width: 60em; */
  height: 100%;
  position: absolute;
  right: -22em;
  top: 0em;
}

@media screen and (max-width: 1024px) {
  .building {
    flex-direction: column;
    margin-top: var(--header-height);
  }
  .building__title {
    font-size: 3.334rem;
  }
  .building__subtitle {
    font-size: 2.084rem;
    width: 100%;
  }
  .building__subtitle2 {
    font-size: 2.084rem;
  }

  .building__button {
    display: flex;
    margin: auto;
    margin-top: 3.5em;
  }

  .building__text-container {
    width: 70%;
  }
  .building__image {
    position: relative;
    right: auto;
    top: auto;
    width: 90%;
    margin-top: 10em;
  }
}