@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Zen Dots';
  src: url(../fonts/ZenDots-Regular.ttf);
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Raleway';
  src: url(../fonts/Raleway-VariableFont_wght.ttf);
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Raleway';
  src: url(../fonts/Raleway-VariableFont_wght.ttf);
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Raleway';
  src: url(../fonts/Raleway-Italic-VariableFont_wght.ttf);
  font-weight: 400;
  font-style: italic;
}