.main-page-not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: var(--header-height);
  padding-top: 14.844em;
  padding-bottom: 11.3544em;
}
.main-page-not-found__content-container {
  display: flex;
  height: 100%;
}
.main-page-not-found__main-text-container {
  position: relative;
  margin-right: 6.407em;
}
.main-page-not-found__h1 {
  font-size: 18.2292rem;
  font-weight: 700;
  line-height: 80%;
  color: var(--purple);
  margin: 0;
  margin-top: .03em;
}
.main-page-not-found__h2 {
  font-family: 'Zen Dots';
  font-size: 3.334rem;
  margin: 0;
}
.main-page-not-found__h3 {
  font-family: 'Zen Dots';
  font-size: 1.875rem;
}
.main-page-not-found__p {
  font-size: 1.042rem;
}
.main-page-not-found__go-home {
  color: var(--purple);
  text-decoration: none;
  font-size: 1.25rem;
  font-weight: 700;
  display: flex;
}
.main-page-not-found__image-container {
  position: absolute;
  top: -52%;
  left: 29.5%;
  margin: 0;
  width: 20.209em;
}
.main-page-not-found__image-container img {
  width: 100%;
}
.main-page-not-found__texts-container {
  width: 32em;
}

@media screen and (max-width: 1024px) {
  .main-page-not-found {
    padding-top: 12.4em;
    padding-bottom: 5.73em;
  }
  .main-page-not-found__content-container {
    flex-direction: column;
    align-items: center;
  }
  .main-page-not-found__main-text-container {
    margin-right: 0;
    width: min-content;
  }
  .main-page-not-found__texts-container {
    margin-top: 7em;
  }
  .main-page-not-found__h1 {
    font-size: 10.417rem;
  }
  .main-page-not-found__h2 {
    font-size: 2.5rem;
  }
  .main-page-not-found__h3 {
    font-size: 2.084rem;
  }
  .main-page-not-found__p {
    font-size: 1.459rem;
    margin: 0;
  }
  .main-page-not-found__go-home {
    font-size: 2.084rem;
    margin-top: 1.05em;
  }
  .main-page-not-found__image-container {
    width: 12.293em;
    top: -48%;
    left: 15%;
  }
  .main-page-not-found__arrow {
    width: 1.67em;
    margin-left: 1.8em;
  }
}