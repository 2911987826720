* {
  --header-height: 6.667rem;

  --dark: rgba(0, 0, 0, .7);
  --dark-one: rgba(0, 0, 0, .72);
  --dark-two: rgba(0, 0, 0, .54);

  --green: #00CFAA;
  --green-one: #95C11F;
  --green-two: #00C5BD;
  --green-three: #3AB7B5;
  
  --blue: #0a1656;
  --blue-one: #4FB1FF;

  --sky-blue: #E9F3FC;
  --sky-blue-one: #EAF3FC;
  --sky-blue-two: #F1F7FD;
  --sky-blue-three: #F8FBFF;
  --sky-blue-four: #EBF4FC;
  --sky-blue-five: rgba(233, 243, 252, .9);
  --sky-blue-six: rgba(248, 251, 255, .7);
  --sky-blue-seven: rgba(234, 243, 252, .7);
  --sky-blue-eight: rgba(235, 244, 252, .7);
  --sky-blue-nine: rgba(250, 253, 255, .7);

  --purple: #3100E0;
  --purple-one: #9800D4;
  --purple-two: rgba(49, 0, 224, .85);
  --purple-three: #2C2163;
  
  --orange: #F0A400;
  --orange-one: #E96D68;




  --gray-800: #262626;
  --gray-700: #3E3E3E;
  --gray-600: #606060;
  --gray-500: #7D7D7D;
  --gray-400: #A3A3A3;
  --gray-300: #CCCCCC;
  --gray-200: #E3E3E3;
  --gray-100: #F8F8F8;
  --gray-blue: #E9F3FC;

  --blue-900: #3100E0;
  --blue-800: #4818F3;
  --blue-700: #805CFF;
  --blue-500: #D0C3FF;
  --blue-100: #EFEBFF;
  --blue-second-800: #0B0027;
  --blue-second-700: #210074;

  --pink-900: #FF3897;
  --pink-600: #FF84BF;
  --pink-300: #FFC1DF;
  --pink-100: #FFEAF4;
  
  --turquoise-900: #00CFAA;
  --turquoise-600: #4BEBCE;
  --turquoise-300: #A9FFEF;
  --turquoise-100: #E4FFFA;
}

@media screen and (max-width: 1024px) {
  * {
    --header-height: 8.917em;
  }
}